export const TOOLS = {
  measuring: "measuring",
  area: "area",
  tags: "tags",
  markers: "markers",
  volume: "volume",
  inspection: "inspection",
  flightImages: "flightImages",
}
export const WIDGETS = {
  markerList: "markerList",
  editMarker: "editMarker",
  models: "models",
  editModels: "editModels",
  imageryPlansList: "imageryPlansList",
  editImagery: "editImagery",
}
export const SCENE_MODES = {
  _2D: "2D",
  _3D: "3D",
}
export const TAGS_TYPES = {
  info: "Info",
  danger: "Danger",
  restricted: "Restricted",
  hyperlink: "Hyperlink",
  aerialshot: "AerialShot",
}

export const PANO_VIEWER_TYPE = {
  aerialshot: "aerial-shot",
  _360Path: "_360Path",
}

export const MESSAGES_TYPES = {
  liveView: "liveView",
  editSnapshot: "editSnapshot",
  weather: "weather",
  cameraInfo: "cameraInfo",
  analyticsEvent: "analyticsEvent",
  ready: "ready",
  info: "info",
  gateReport: "gateReport",
  createCompare: "createCompare",
  uploadDrone: "uploadDrone",
  uploadBIM: "uploadBIM",
  history: "history",
  shareLink: "shareLink",
}

export const CAMERA_MARKER_TYPES = {
  default: "Default",
  gate: "Gate",
  indoor: "Indoor",
}

export const IMAGERY_PLANS_TYPES = [
  "Mep",
  "Satellite",
  "Floor",
  "Site",
  "Custom",
]

export const TUTORIALS = [
  {
    name: "Navigation",
    children: [
      {
        name: "Pan",
        path: "navigation/pan",
      },
      {
        name: "Rotate",
        path: "navigation/rotate",
      },
      {
        name: "Zoom",
        path: "navigation/zoom",
      },
    ],
  },
  {
    name: "Measuring",
    children: [
      {
        name: "Distances",
        path: "measuring/distances",
      },
      { name: "Area", path: "measuring/area" },
      { name: "Volumetrics", path: "measuring/volumetrics" },
    ],
  },
  {
    name: "Tags",
    children: [
      {
        name: "Create",
        path: "tags/create",
      },
      { name: "Show", path: "tags/show" },
    ],
  },
  {
    name: "Settings",
    children: [
      {
        name: "3D",
        children: [
          {
            name: "Quality",
            path: "settings/3d/quality",
          },
        ],
      },
      {
        name: "2D",
        children: [
          {
            name: "Plans",
            path: "settings/2d/plans",
          },
        ],
      },
      {
        name: "Map Style",
        path: "settings/map-style",
      },
    ],
  },
  {
    name: "Edit Tool",
    path: "edit-tool",
  },
  {
    name: "Camera View",
    path: "camera-view",
  },
  {
    name: "Compass",
    path: "compass",
  },
  {
    name: "Timeline",
    path: "timeline",
  },
  {
    name: "Compare",
    path: "compare",
  },
  {
    name: "Weather",
    path: "weather",
  },
  {
    name: "BIM/ OSM",
    path: "surrounding-buildings",
  },
  {
    name: "Scene Switch",
    path: "scene-switch",
  },
  {
    name: "Point Cloud",
    path: "point-cloud",
  },
  {
    name: "Inspection Tool",
    path: "inspection-tool",
  },
  {
    name: "Ingest",
    path: "ingest",
  },
]
